.sector .container {
	background-image: 
		url(img/lines/left.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: 50% 460px, 50% -150px;
	background-size: 154px 3000px, 2600px 2479px;
}

.sector.scroll .container {
	background-position: 50% 410px, 50% -200px;
}

.sector-text h2 {
	top: -260px;
}

.sector-content {
	padding-top: 420px;
	padding-bottom: 0px;
}

.sector-text.inner {
	padding-right: 700px;
	padding-bottom: 150px;
}

.sector-clients {
	background-color: #fff;
	padding-top: 60px;
	padding-bottom: 64px;
	width: 920px;
	text-align: center;
}

.sector-gallery {
	position: absolute;
	right: 100px;
	top: 110px;
}

.sector-image {
	position: absolute;
	right: 50px;
	top: -150px;
}

.sector-content .head-paginate {
	margin-top: 160px;
}