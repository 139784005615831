.home-news {
	padding-bottom: 300px;
}

.home-news-intro {
	margin-left: 80px;
}

.home-news-intro h2 {
	font-size: 32px;
}

.home-news-item {
	height: 370px;
	padding-left: 490px;
	padding-top: 160px;
	padding-right: 100px;
}

.home-news-item img {
	position: absolute;
	left: 80px;
	top: 0px;
	width: 380px;
	height: 308px;
}

.home-news-item h2 {
	font-size: 32px;
}

.home-news-item h3 {
	font-size: 16px;
}

.home-news-item p {
	font-size: 12px;
	line-height: 16px;
}

.home-news-item-more {
	position: absolute;
	top: 310px;
	margin-left: -80px;
}