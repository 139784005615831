.story .container {
	background-image: 
		url(img/lines/left.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: calc(50% - 460px) 163px, 50% -330px;
	background-size: 154px 3000px, 2600px 2479px;
}

.scroll.story .container {
	background-position: calc(50% - 460px) 113px, 50% -380px;
}

.story-content .inner {
	padding: 0px 64px 0px 64px;
}

.story-text.inner {
	padding-right: 550px;
	padding-bottom: 64px;
	min-height: 500px;
}

.story-image {
	position: absolute;
	right: 64px;
	top: 110px;
	width: 310px;
}