.product .container {
	background-image: 
		url(img/lines/middle-long.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: 50% 466px, 50% -330px;
	background-size: 1614px 2983px, 2600px 2479px;
}

.scroll.product .container {
	background-position: 50% 416px, 50% -380px;
}

.product-content {
	background-image: 
		url(img/sketch/d.png)
	;
	background-repeat: no-repeat;
	background-position: 100% 400px;
	padding-bottom: 0px!important;
}

.product-content.with-casestudy {
	background-image: 
		url(img/sketch/d.png),
		url(img/band-foot.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: 100% 400px, 50% 100%;
}

.product-text.inner {
	padding-right: 700px;
}

.product-content.with-casestudy .product-text.inner {
	padding-bottom: 50px;
}

.product-content.with-casestudy.with-animation .product-text.inner {
	padding-bottom: 150px;
	min-height: 1000px;
}

.product-animation {
	padding-top: 32px;
	padding-bottom: 32px;
	width: 700px;
	text-align: center;
}

.product-content.with-animation .product-animation {
	padding: 32px;
	width: 764px;
	margin-bottom: 32px;
	border: 1px solid #ccc;
}

.product-animation p {
	width: 400px;
	margin: 0px auto;
}

.product-study {
	height: 650px;
	padding-top: 260px;
	width: 400px;
	text-align: center;
}

.product-image {
	position: absolute;
	right: 50px;
	top: -150px;
}

.product-animation-video {
	padding-top: 32px;
	height: 400px;
	width: 700px;
}

.product-content .head-paginate {
	margin-top: 105px;
}