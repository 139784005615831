.home-feature {
	height: 880px;
	padding-top: 100px;
}

.home-feature-slide h2 {
	font-size: 64px;
	line-height: 72px;
	width: 100px;
}

.home-feature-slide {
	height: 780px;
}

.home-background,
.home-background-slide {
	height: 1080px;
}