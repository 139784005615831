.service .container {
	background-image: 
		url(img/lines/middle-long.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: 50% 466px, 50% -330px;
	background-size: 1614px 2983px, 2600px 2479px;
}

.scroll.service .container {
	background-position: 50% 416px, 50% -380px;
}

.service-text.inner {
	padding-right: 700px;
}

.service-content.with-casestudy .service-text.inner {
	padding-bottom: 150px;
	min-height: 1000px;
}

.service-content {
	background-image: 
		url(img/sketch/d.png)
	;
	background-repeat: no-repeat;
	background-position: 100% 400px;
}

.service-content.with-casestudy {
	background-image: 
		url(img/sketch/d.png),
		url(img/band-foot.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: 100% 400px, 50% 100%;
}

.service-study {
	height: 650px;
	padding-top: 260px;
	width: 400px;
	text-align: center;
}

.service-gallery {
	position: absolute;
	right: 180px;
	top: 100px;
}

.service-content .head-paginate {
	margin-top: 105px;
}