body {
	background-size: 100% auto;
}

.inner {
	width: 1100px;
}

.content {
	min-height: calc(100vh - 330px);
}

.content-generic {
	padding: 250px 0px 64px 0px;
}

.text > h2 {
	font-size: 48px;
	line-height: 52px;
	margin-bottom: 32px;
}

.text > h3 {
	font-size: 32px;
	margin-top: 48px;
	margin-bottom: 24px;
}

.container {
	background-image: url(img/header/overlay.png);
	background-position: 50% -330px;
	background-size: 2600px 2479px;
}

body.scroll {
	margin-top: 50px;
}

.scroll .container {
	background-position: 50% -380px;
}

