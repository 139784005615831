.home-products-intro {
	margin-left: calc(50% + 100px);
}

.home-products-intro h2 {
	font-size: 32px;
}

.home-products-boxes {
	height: 466px;
	margin-bottom: 224px;
}

.home-products-boxes-item {
	position: absolute;
	display: block;
	width: 188px;
	height: 154px;
}

.home-products-boxes-more {
	position: absolute;
	display: block;
}

.home-products-boxes-item-text {
	width: 188px;
	height: 154px;
}

.home-products-boxes-item-a {
	left: calc(50% - 290px);
	top: 0px;
}

.home-products-boxes-item-b {
	left: calc(50% - 100px);
	top: 0px;
}

.home-products-boxes-item-c {
	left: calc(50% - 100px);
	top: 156px;
}

.home-products-boxes-item-d {
	left: calc(50% + 90px);
	top: 312px;
}

.home-products-boxes-item-e {
	left: calc(50% + 90px);
	height: 310px;
	width: 378px;
}

.home-products-boxes-more {
	left: calc(50% + 280px);
	top: 312px;
}

.home-products-boxes-item-e .home-products-boxes-item-text {
	height: 310px;
	width: 378px;
}

.home-products-boxes-item span {
	margin-top: 32px;
	background-image: url(img/square.png);
	background-repeat: no-repeat;
	background-size: 64px 64px;
	background-position: 50% 0px;
	font-family: 'Oswald', sans-serif;
	color: #ffffff;
	box-sizing: border-box;
	display: block;
	line-height: 20px;
	font-size: 16px;
	padding: 65px 16px 16px 16px;
	text-align: center;
	text-transform: uppercase;
}

.home-products-boxes-item-e span {
	margin-top: 120px;
}