.foot {
	height: 280px;
}

.foot .inner {
	height: 260px;
}

.foot-column {
	height: 260px;
}

.foot-links {
	width: 280px;
}

.foot-contact-address {
	width: 280px;
}

.foot-contact-direct {
	width: 540px;
}