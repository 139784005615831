.home .container {
	background-image: 
		url(img/lines/middle.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: 50% 796px, 50% 0px;
	background-size: 1614px 983px, 2600px 2479px;
}

.scroll.home .container {
	background-position: 50% 746px, 50% -50px;
}

.home-content {
	background: 
		url(img/band-middle.png) 50% 532px no-repeat,
		url(img/sketch/a.png) 0px 0px no-repeat,
		url(img/sketch/b.png) 100% 1292px no-repeat
	;
}