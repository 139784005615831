.news .container {
	background-image: 
		url(img/lines/left.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: calc(50% - 460px) 163px, 50% -330px;
	background-size: 154px 3000px, 2600px 2479px;
}

.scroll.news .container {
	background-position: calc(50% - 460px) 113px, 50% -380px;
}

.news-content .inner {
	padding: 0px 64px 0px 64px;
}

.news-item {
	height: 204px;
	margin-bottom: 30px;
}

.news-item-content {
	position: absolute;
	bottom: 0px;
	left: 232px;
}

.news-item img {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 200px;
	height: 200px;
	height: auto;
}

.news-item h2 {
	font-size: 32px;
}

.news-item h3 {
	font-size: 16px;
}

.news-item p {
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 0px;
}