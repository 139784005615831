.casestudy .container {
	background-image: 
		url(img/lines/top.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: calc(50% - 320px) 159px, 50% -330px;
	background-size: 380px 2052px, 2600px 2479px;
}

.scroll.casestudy .container {
	background-position: calc(50% - 320px) 109px, 50% -380px;
}

.casestudy-content.content-generic {
	padding-bottom: 0px;
}

.casestudy-text.inner {
	padding: 0px 620px 50px 80px;
}

.casestudy-image {
	position: absolute;
	right: 64px;
	width: 440px;
	height: 440px;
}

.casestudy-gallery {
	position: relative;
	width: 600px;
	margin-left: 350px;
}