.services .container {
	background-image: 
		url(img/lines/left.png), 
		url(img/header/overlay.png)
	;
	background-repeat: no-repeat, no-repeat;
	background-position: calc(50% - 460px) 163px, 50% -330px;
	background-size: 154px 3000px, 2600px 2479px;
}

.scroll.services .container {
	background-position: calc(50% - 460px) 113px, 50% -380px;
}

.services-content .inner {
	padding: 0px 64px 0px 64px;
}

.services-item {
	float: left;
	width: 238px;
	height: 204px;
	margin: 1px;
}

.services-item-text {
	height: 204px;
	background: rgba(0, 0, 0, 0.5);
	transition: background 100ms linear;
}

.services-item-text:hover {
	background: rgba(0, 0, 0, 0);
}

.services-item-text span {
	background-position: 50% 60px;
	background-size: 64px 64px;
	font-size: 16px;
	padding: 135px 16px 16px 16px;
	text-align: center;
}